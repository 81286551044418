import { Controller, useFormContext } from "react-hook-form";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormHelperText } from "@material-ui/core";

import { forwardRef } from "react";
import { DATE_FORMAT } from "../../pages/dataAccess/queryAndDownload/constants";

export const DateTimePickerWithRef = forwardRef((props, ref) => (
  <DateTimePicker {...props} ref={ref} />
));

export const DateTime = ({ name, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl fullWidth error={!!errors[name]}>
        <Controller
          name={name}
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <DateTimePickerWithRef
              {...rest}
              format={DATE_FORMAT}
              inputVariant="outlined"
              autoOk
              label={label}
              error={!!errors[name]}
            />
          )}
        />
        {!!errors[name] && (
          <FormHelperText>{errors[name]?.message}</FormHelperText>
        )}
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};
