import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { DatePicker, MultiSelect, Select } from "@lrewater/lre-react";
import {
  dateFormatter,
  extractDate,
  groupByValueArray,
  lineColors,
} from "../../../utils";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid as MuiGrid,
  lighten,
  Tooltip,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import { customSecondary } from "../../../theme/variants";
import { Alert } from "@material-ui/lab";
import TimeseriesComparisonMap from "../../../components/map/TimeseriesComparisonMap";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import { add } from "date-fns";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  // overflow-y: auto;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const Button = styled(MuiButton)(spacing);

const SidebarSection = styled(MuiTypography)`
  ${spacing};
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const MapContainer = styled.div`
  height: 406px;
  width: 100%;
`;

const TimeSeriesComparison = () => {
  const saveRef = useRef(null);

  const Endpoints = [
    { label: "Daily", value: "ts-compare-daily-data" },
    { label: "15 Minutes", value: "ts-compare-raw-data" },
  ];

  const [filterValues, setFilterValues] = useState({
    endpoint: "ts-compare-daily-data",
    waterbodies: [],
    parameterLeft: 67,
    locationsLeft: [4],
    parameterRight: 1772,
    locationsRight: [2],
    startDate: extractDate("10/1/2021"),
    endDate: extractDate(new Date()),
  });

  const { data: Waterbodies } = useQuery(
    ["ts-compare-dropdown-waterbodies"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-compare-dropdown-waterbodies`
        );

        setFilterValues((prevState) => {
          let newValues = { ...prevState };
          const selectAllWaterbodies = data.map(
            (waterbody) => waterbody.waterbody_ndx
          );
          newValues["waterbodies"] = selectAllWaterbodies;
          return newValues;
        });

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Parameters } = useQuery(
    ["ts-compare-dropdown-parameters"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-compare-dropdown-parameters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Locations } = useQuery(
    ["ts-compare-dropdown-locations-assoc"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ts-compare-dropdown-locations-assoc`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const filterLocationsByParameter = (locations, parameter) => {
    return locations.filter((location) =>
      location.parameter_ndx_array.includes(parameter)
    );
  };

  const filterLocationsByWaterbodies = (locations, waterbodies) => {
    return locations.filter(
      (location) =>
        waterbodies.filter((waterbody) =>
          location.waterbody_ndx_array.includes(waterbody)
        ).length
    );
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      if (name === "parameterLeft") {
        newValues["locationsLeft"] = [];
      }
      if (name === "parameterRight") {
        newValues["locationsRight"] = [];
      }

      if (name === "waterbodies") {
        newValues["locationsLeft"] = [];
        newValues["locationsRight"] = [];
        newValues["parameterLeft"] = "";
        newValues["parameterRight"] = "";
      }

      newValues[name] = value;

      return newValues;
    });
  };

  const { data, error, isFetching, refetch } = useQuery(
    ["ts-compare-daily-data"],
    async () => {
      try {
        const { data: dataLeftAxis } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.parameterLeft}/${filterValues.locationsLeft}/${filterValues.startDate}/${filterValues.endDate}`
        );

        const { data: dataRightAxis } =
          filterValues.locationsRight.length > 0
            ? await axios.get(
                `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.parameterRight}/${filterValues.locationsRight}/${filterValues.startDate}/${filterValues.endDate}`
              )
            : { data: [] };

        const groupedData = {
          leftAxis: groupByValueArray(dataLeftAxis, "location_ndx"),
          rightAxis: groupByValueArray(dataRightAxis, "location_ndx"),
        };

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
    }
  );

  const tableColumns = [
    { title: "Parameter Name", field: "parameter" },
    { title: "Location Name", field: "location" },
    {
      title: "Result",
      field: "result_value",
      render: (rowData) => {
        return rowData.result_value.toFixed(2);
      },
    },
    { title: "Units", field: "units" },
    {
      title: "Last Report",
      field: "collect_timestamp",
      render: (rowData) => {
        return dateFormatter(rowData.collect_timestamp, "MM/DD/YYYY, h:mm A");
      },
    },
    { title: "Data Provider Name", field: "data_source" },
    { title: "Location Type Name", field: "location_type" },
  ];

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data) {
      let count = -1;
      const graphData = {
        yLLabel: data?.leftAxis?.length
          ? `${data?.leftAxis[0][0]?.parameter} (${data?.leftAxis[0][0]?.units})`
          : null,
        yRLabel: data?.rightAxis?.length
          ? `${data?.rightAxis[0][0]?.parameter} (${data?.rightAxis[0][0]?.units})`
          : null,
        datasets: [
          ...data.rightAxis.map((location) => {
            count++;
            return {
              data: location.map((item) => {
                return {
                  x: item.collect_timestamp,
                  y: item.result_value,
                };
              }),
              yAxisID: "yR",
              units: location[0].units,
              pointStyle: "circle",
              borderWidth: 2,
              borderCapStyle: "round",
              borderDash: [8, 10],
              pointRadius: 0,
              pointHoverRadius: 4,
              label: `${location[0].location} (right axis / ${location[0].units})`,
              borderColor: Object.values(lineColors)[count],
              backgroundColor: lighten(Object.values(lineColors)[count], 0.5),
              tension: 0.5,
            };
          }),
          ...data.leftAxis.map((location) => {
            count++;
            return {
              data: location.map((item) => {
                return {
                  x: item.collect_timestamp,
                  y: item.result_value,
                };
              }),
              yAxisID: "yL",
              units: location[0].units,
              pointStyle: "circle",
              fill: false,
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 4,
              label: `${location[0].location} (left axis / ${location[0].units})`,
              borderColor: Object.values(lineColors)[count],
              backgroundColor: lighten(Object.values(lineColors)[count], 0.5),
              tension: 0.5,
            };
          }),
        ],
      };
      setGraphData(graphData);
    }
  }, [data]);

  return (
    <>
      <Helmet title="Time Series Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Time Series Comparisons
      </Typography>

      {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
      {/*  <Link component={NavLink} exact to="/dashboard">*/}
      {/*    Dashboard*/}
      {/*  </Link>*/}
      {/*  <Typography>Time Series Comparisons</Typography>*/}
      {/*</Breadcrumbs>*/}

      <Divider my={6} />

      <Grid container spacing={6}>
        {Locations && Waterbodies && (
          <Grid item xs={12} md={12} lg={12} xl={5}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map"
                id="map"
              >
                <Typography variant="h4" ml={2}>
                  Map
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MapContainer>
                  <TimeseriesComparisonMap
                    selectedWaterbodyLocations={filterLocationsByWaterbodies(
                      Locations,
                      filterValues.waterbodies
                    ).map((location) => location.location_ndx)}
                    selectedLeftLocations={filterValues.locationsLeft}
                    selectedRightLocations={filterValues.locationsRight}
                  />
                </MapContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {Parameters && Locations && Waterbodies && (
          <>
            <Grid item xs={12} md={12} lg={12} xl={7}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Typography variant="h4" ml={2}>
                    Filter Controls
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <Grid container pb={6} mt={2}>
                      <Grid item xs={12}>
                        <SidebarSection>Filters</SidebarSection>
                        <Select
                          name="endpoint"
                          label="Graph Options"
                          variant="outlined"
                          valueField="value"
                          displayField="label"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={Endpoints}
                          value={filterValues.endpoint}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 162px - 162px)" }}
                        />

                        <DatePicker
                          name="startDate"
                          label="Start Date"
                          variant="outlined"
                          outlineColor="primary"
                          labelColor="primary"
                          value={filterValues.startDate}
                          onChange={handleFilter}
                          width={150}
                        />
                        <DatePicker
                          name="endDate"
                          label="End Date"
                          variant="outlined"
                          outlineColor="primary"
                          labelColor="primary"
                          value={filterValues.endDate}
                          onChange={handleFilter}
                          width={150}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <MultiSelect
                          name="waterbodies"
                          label="Water Body"
                          variant="outlined"
                          valueField="waterbody_ndx"
                          displayField="waterbody_name"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={Waterbodies}
                          value={filterValues.waterbodies}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 162px - 162px)" }}
                        />
                        <div
                          style={{
                            width: "316px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Quick set dates">
                            <Button
                              onClick={() => {
                                setFilterValues((prevState) => {
                                  let newValues = { ...prevState };

                                  newValues.startDate =
                                    extractDate("10/1/2021");
                                  newValues.endDate = extractDate(new Date());

                                  return newValues;
                                });
                              }}
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="small"
                            >
                              Oct 1 2021
                            </Button>
                          </Tooltip>
                          <Tooltip title="Quick set dates">
                            <Button
                              ml={3}
                              onClick={() => {
                                setFilterValues((prevState) => {
                                  let newValues = { ...prevState };

                                  newValues.startDate = extractDate(
                                    add(new Date(), { days: -30 })
                                  );
                                  newValues.endDate = extractDate(new Date());

                                  return newValues;
                                });
                              }}
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="small"
                            >
                              Last 30 Days
                            </Button>
                          </Tooltip>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <SidebarSection>Left Axis</SidebarSection>
                        <Select
                          name="parameterLeft"
                          label="Parameters"
                          variant="outlined"
                          valueField="parameter_ndx"
                          displayField="parameter"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={filterLocationsByWaterbodies(
                            Parameters,
                            filterValues.waterbodies
                          )}
                          value={filterValues.parameterLeft}
                          onChange={handleFilter}
                          width={220}
                        />
                        <MultiSelect
                          name="locationsLeft"
                          label="Locations"
                          variant="outlined"
                          valueField="location_ndx"
                          displayField="location"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={filterLocationsByParameter(
                            filterLocationsByWaterbodies(
                              Locations,
                              filterValues.waterbodies
                            ),
                            filterValues.parameterLeft
                          )}
                          value={filterValues.locationsLeft}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 236px)" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SidebarSection>Right Axis</SidebarSection>
                        <Select
                          name="parameterRight"
                          label="Parameters"
                          variant="outlined"
                          valueField="parameter_ndx"
                          displayField="parameter"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={filterLocationsByWaterbodies(
                            Parameters,
                            filterValues.waterbodies
                          )}
                          value={filterValues.parameterRight}
                          onChange={handleFilter}
                          width={220}
                        />
                        <MultiSelect
                          name="locationsRight"
                          label="Locations"
                          variant="outlined"
                          valueField="location_ndx"
                          displayField="location"
                          outlineColor="primary"
                          labelColor="primary"
                          margin="normal"
                          data={filterLocationsByParameter(
                            filterLocationsByWaterbodies(
                              Locations,
                              filterValues.waterbodies
                            ),
                            filterValues.parameterRight
                          )}
                          value={filterValues.locationsRight}
                          onChange={handleFilter}
                          style={{ width: "calc(100% - 236px)" }}
                        />
                      </Grid>
                      <SubmitGrid item container>
                        <Grid item style={{ width: "calc(100% - 162px)" }}>
                          {!data && (
                            <Alert severity="info">
                              After selecting your timeseries inputs, click the
                              'Submit' button to load an interactive timeseries
                              plot for comparison across different locations and
                              parameters.
                            </Alert>
                          )}
                          {(data?.leftAxis?.length === 0 ||
                            data?.rightAxis?.length === 0) && (
                            <Alert severity="warning">
                              No data available for{" "}
                              {data?.leftAxis?.length === 0 &&
                              data?.rightAxis?.length === 0
                                ? "left or right"
                                : data?.leftAxis?.length === 0
                                ? "left"
                                : "right"}{" "}
                              axis
                            </Alert>
                          )}
                        </Grid>
                        <Grid item>
                          <SaveGraphButton
                            ref={saveRef}
                            title="Timeseries Comparison Graph"
                            disabled={isFetching || !data}
                          />
                          <Button
                            onClick={() => refetch()}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            size="large"
                            style={{ marginLeft: "10px" }}
                            disabled={
                              !filterValues.locationsLeft.length
                              // ||
                              // !filterValues.locationsRight.length
                            }
                          >
                            Submit
                          </Button>
                        </Grid>
                      </SubmitGrid>
                    </Grid>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>

      {Parameters && Locations && Waterbodies && (
        <>
          {data && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="time-series"
                    id="time-series"
                  >
                    <Typography variant="h4" ml={2}>
                      Graph
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TimeseriesContainer>
                        <TableWrapper>
                          <TimeseriesLineChart
                            // yLReverse={filterValues.parameterLeft === 7}
                            // yRReverse={filterValues.parameterRight === 7}
                            data={graphData}
                            error={error}
                            isLoading={isFetching}
                            filterValues={filterValues}
                            locationsOptions={Locations}
                            yLLabel={graphData?.yLLabel}
                            yRLabel={graphData?.yRLabel}
                            xLabelUnit="week"
                            ref={saveRef}
                            tooltipFormat={
                              filterValues.endpoint === "ts-compare-raw-data"
                                ? "MM-DD-YYYY, h:mm A"
                                : "MM-DD-YYYY"
                            }
                            footerLabel="Hours Pumped"
                          />
                        </TableWrapper>
                      </TimeseriesContainer>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}

          {data && !isFetching && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="table-content"
                    id="table-header"
                  >
                    <Typography variant="h4" ml={2}>
                      Table
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <TableWrapper>
                        <Table
                          // isLoading={isLoading}
                          label="Daily Groundwater Elevation Timeseries Table"
                          columns={tableColumns}
                          data={[
                            ...[].concat.apply([], data?.leftAxis),
                            ...[].concat.apply([], data?.rightAxis),
                          ]}
                          height="590px"
                        />
                      </TableWrapper>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default TimeSeriesComparison;
