import { addMonths } from "date-fns";
import * as yup from "yup";
import { nonEmptyArray } from "./utils";

export const DATE_FORMAT = "MMM dd yyyy, hh:mm a";

export const PAGINATION_OPTIONS = [10, 25, 50, 100, "All"];
export const DEFAULT_PAGINATION = PAGINATION_OPTIONS[2];

export const VALIDATION_SCHEMA = yup.object({
  timestep: yup.string().required("Time step is required"),
  waterbodies: nonEmptyArray("Water sources are required"),
  locationTypes: nonEmptyArray("Location types are required"),
  parameters: nonEmptyArray("Parameters are required"),
  locations: nonEmptyArray("Locations are required"),
  startDate: yup.date().required("Start date is required"),
  endDate: yup.date().required("End date is required"),
});

export const DEFAULT_VALUES = {
  timestep: "q-d-data-ts-daily",
  waterbodies: [],
  locationTypes: [],
  parameters: [],
  locations: [],
  startDate: addMonths(new Date(), -3),
  endDate: new Date(),
};

export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

export const FILENAME = `LowerLathamData-${new Date()}.csv`;
