export const BOOLEAN_OPTIONS = [
  {
    value: true,
    option: "Yes",
  },
  {
    value: false,
    option: "No",
  },
];

export const DEFAULT_BASIC_LOCATION_INFO_STATE = {
  location_ndx: null,
  location_id: null,
  location_display_label: null,
  location_name: null,
  location_type_ndx: null,
  waterbody_ndx: null,
  structure_type_ndx: null,
  legend_color: "#000000",
  order_by: null,
  notes: null,
  latitude: null,
  longitude: null,
  has_control: false,
  exclude_from_map: false,
};
