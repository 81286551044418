import React from "react";
import styled from "styled-components/macro";
import AppBar from "./AppBar";
import Introduction from "./Introduction";
import JoinUs from "./JoinUs";
import Footer from "../../../components/Footer";

const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function Presentation() {
  return (
    <PageContainer>
      <AppBar />
      <Introduction />
      <JoinUs />
      <Footer />
    </PageContainer>
  );
}

export default Presentation;
