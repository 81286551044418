import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import {
  Opacity,
  VerticalAlignBottom,
  Assessment,
  Autorenew,
  CallSplit,
  ShowChart,
  LinearScale,
  TrendingUp,
} from "@material-ui/icons";
import { dateFormatter } from "../utils";
import { Alert } from "@material-ui/lab";

import "echarts-liquidfill";
import ReactECharts from "echarts-for-react";

const getLiquidFillOption = (data) => ({
  series: [
    {
      data: data,
      type: "liquidFill",
      silent: true,
      shape: "container",
      outline: {
        borderDistance: 2,
        itemStyle: {
          borderWidth: 4,
        },
      },
      label: {
        fontSize: 30,
        formatter: function (d) {
          return `${Math.round(d.value * 1000) / 10}% Full`;
        },
        position: ["50%", "65%"],
      },
      amplitude: "10%",
      waveLength: "150%",
    },
  ],
});

const ModelCardRoot = styled(Card)`
  border: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ModelCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DataChip = styled(Chip)`
  height: 24px;
  width: 70px;
  margin-left: 8px;
  font-weight: 600;
`;

const DateChip = styled(Chip)`
  height: 24px;
  margin-left: auto;
  width: auto;
  font-weight: 600;
`;

const ModelCardActions = styled(CardActions)`
  background: #fafafa;
  border-top: 1px solid #eaecf0;
  height: 45px;
  justify-content: space-between;
`;

const TruncatedTypographyTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
`;

const ActionBox = styled(Box)`
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewButton = styled(Button)`
  margin-left: auto;
  margin-right: 8px;
`;

const Spacer = styled.div`
  margin-right: 6px;
`;

const isDataPresent = (dataField) =>
  dataField !== null && dataField !== undefined;

const locationTypeToIcon = {
  41: Autorenew, // Deliveries to Recharge
  43: Opacity, // Deliveries to Stream
  42: VerticalAlignBottom, // Deliveries to Reservoir
  45: Assessment, // In Ditch Check Flows
  46: CallSplit, // Ditch Bypass
  14: ShowChart, // LL Reservoir Levels
  48: LinearScale, // Deliveries to Ditch
  47: TrendingUp, // Recharge Pond Levels
};

const dataFields = [
  { key: "flow_cfs", title: "Flow" },
  { key: "stage_ft", title: "Stage" },
  { key: "gw_depth_ft", title: "GW Depth" },
  { key: "water_level_ft", title: "Water Level" },
  { key: "surface_acres", title: "Surface Area" },
  { key: "volume_af", title: "Volume" },
  { key: "gate_height_in", title: "Gate Height" },
];

const DataField = ({ title, value }) => {
  return (
    isDataPresent(value) && (
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <DataChip color="primary" variant="outlined" label={value ?? "--"} />
        </Grid>
      </Grid>
    )
  );
};

export const MobileCard = ({ data = {} }) => {
  const Icon = locationTypeToIcon[data?.location_type_ndx] ?? null;

  const anyDataPresent =
    isDataPresent(data?.pct_full) ||
    dataFields.some((field) => isDataPresent(data[field.key]));

  const fillData = new Array(4).fill(data?.pct_full);
  const liquidFillOption = getLiquidFillOption(fillData);

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <ModelCardRoot variant="outlined">
        <ModelCardContent>
          <TruncatedTypographyTitle variant="h4">
            {data?.location_name ?? "--"}
          </TruncatedTypographyTitle>

          {anyDataPresent ? (
            <>
              {dataFields.map((field) => (
                <DataField
                  key={field.key}
                  title={field.title}
                  value={data[field.key]}
                />
              ))}

              {isDataPresent(data?.pct_full) && (
                <ReactECharts
                  option={liquidFillOption}
                  style={{ width: "100%", height: "50px" }}
                />
              )}

              {isDataPresent(data?.last_report) && (
                <ContentBox>
                  <Typography variant="h6">Last Report</Typography>
                  {data?.is_stale ? (
                    <>
                      <Spacer />
                      <Alert
                        severity="error"
                        style={{
                          flexGrow: 1,
                          padding: "0 6px",
                          justifyContent: "end",
                        }}
                      >
                        <DateChip
                          color="primary"
                          variant="outlined"
                          label={dateFormatter(
                            data.last_report,
                            "MM/DD/YYYY, h:mm A"
                          )}
                        />
                      </Alert>
                    </>
                  ) : (
                    <DateChip
                      color="primary"
                      variant="outlined"
                      label={dateFormatter(
                        data.last_report,
                        "MM/DD/YYYY, h:mm A"
                      )}
                    />
                  )}
                </ContentBox>
              )}
            </>
          ) : (
            <Typography variant="body1" style={{ marginTop: "10px" }}>
              No data available
            </Typography>
          )}
        </ModelCardContent>
        <ModelCardActions>
          <ActionBox>
            {!!Icon && <Icon style={{ marginRight: "4px" }} />}
            <Typography variant="body2">
              {data?.location_type_desc ?? "--"}
            </Typography>
          </ActionBox>
          {data?.location_link && (
            <ViewButton
              size="small"
              variant="contained"
              href={data.location_link}
            >
              View
            </ViewButton>
          )}
        </ModelCardActions>
      </ModelCardRoot>
    </Grid>
  );
};
