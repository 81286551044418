import React from "react";
import SystemGraphs from "./SystemGraphs";

const KleinPond1Rch = () => {
  const defaultParameter = 67;

  const pageTitle = "Klein Pond 1 Rch";

  const endpoints = [
    { label: "15 Minutes", value: "display008-data15-min-ax1" },
    { label: "Daily", value: "display008-data-daily-ax1" },
  ];

  const parametersEndpoint = "display008-lookup-parameter";

  const mapEndpoint = "display008-map-points";

  return (
    <>
      <SystemGraphs
        defaultParameter={defaultParameter}
        pageTitle={pageTitle}
        endpoints={endpoints}
        parametersEndpoint={parametersEndpoint}
        mapEndpoint={mapEndpoint}
        //if rightAxis is true, then the graph will add a right axis based on the
        //current endpoints, only it will sub a 2 on the end instead of the 1
        rightAxis={true}
        displayIndex={8}
      />
    </>
  );
};

export default KleinPond1Rch;
