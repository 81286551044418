import React from "react";
import LineChart from "./LineChart";
import { DataLoader, GridItem, WidgetContainer } from "./styledComponents";

const LineChartSection = React.memo(({ data, isLoading, customWrap }) => {
  const subtitle =
    data?.[0]?.data?.[0] &&
    `${data[0].data[0].parameter_name}, ${data[0].data[0].units}`;

  return (
    <DataLoader isLoading={isLoading} isData={!!data?.length}>
      <GridItem customWrap={customWrap}>
        <WidgetContainer title="Last 6 Hours" subtitle={subtitle}>
          <LineChart data={data} />
        </WidgetContainer>
      </GridItem>
    </DataLoader>
  );
});

export default LineChartSection;
