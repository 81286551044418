import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  lighten,
  Accordion,
  AccordionDetails,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { customHighlight } from "../../../theme/variants";
import { dateFormatter, standardizeColor } from "../../../utils";
import { useQuery } from "react-query";
import axios from "axios";
import DashBarChart from "../../dataAccess/timeSeries/DashBarChart";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { Loader } from "react-feather";
import Table from "../../../components/Table";

const Typography = styled(MuiTypography)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Hero = styled.div`
  background: url("/static/img/lowerlatham-hero.png") center center / cover
    no-repeat;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Recap = styled.section`
  border-top: 5px solid ${() => customHighlight[500]};
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Description = styled.div`
  background-color: ${({ color = customHighlight[500] }) => color};
  color: white;
  padding: 30px 60px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Welcome = styled.div`
  background-color: rgba(47, 44, 116, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  border-radius: 25px;
  position: absolute;
`;

const AboutLink = styled(Link)`
  color: ${() => customHighlight[500]};
  font-weight: 900;
`;

const TimeseriesContainer = styled.div`
  height: 400px;
  width: 100%;
`;

const GraphWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function Default() {
  const { user } = useAuth0();
  const saveRef = useRef(null);

  const [filterValues, setFilterValues] = useState({
    endpoint: "display-dashgraph-data-hourly",
    parameter: 67,
    hours: 0,
  });

  const handleFilterChange = (name, value) => {
    setFilterValues((prev) => ({ ...prev, [name]: value }));
  };

  const { data: parameters } = useQuery(
    ["Parameters"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-dashboard-parameters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data, error, isFetching } = useQuery(
    [
      `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.parameter}/${filterValues.hours}`,
    ],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${filterValues.parameter}/${filterValues.hours}`
        );

        return data.sort((v1, v2) => {
          return v1.location_label < v2.location_label
            ? -1
            : v1.location_label > v2.location_label
            ? 1
            : 0;
        });
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
      cacheTime: 0,
    }
  );

  const tableColumns = [
    { title: "Location", field: "location_label" },
    { title: "Parameter", field: "parameter_name" },
    { title: "Units", field: "unit_name" },
    {
      title: "Timestamp",
      field: "collect_timestamp",
      render: (rowData) => {
        return dateFormatter(rowData.collect_timestamp, "MM/DD/YYYY, h:mm A");
      },
    },

    { title: "Value", field: "data_value" },
    { title: "Source", field: "datasrc_name" },
  ];

  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data?.length) {
      const graphData = {
        labels: data.map((item) => item.location_label),
        units: data[0].unit_name,
        datasets: [
          {
            location: data.map((item) => item.location_label),
            parameter: data[0].parameter_name,
            timestamp: data.map((item) => item.collect_timestamp),
            source: data.map((item) => item.datasrc_name),
            data: data.map((item) => item.data_value),
            yAxisID: "y",
            units: data[0].unit_name,
            fill: true,
            // borderColor: filterData.map((item, i) => lineColors[i]),
            borderColor: data.map((item) => item.bar_color),
            backgroundColor: data.map((item) =>
              lighten(standardizeColor(item.bar_color), 0.8)
            ),
            // borderRadius: 5,
            borderWidth: 1,
            // barThickness: "flex",
            maxBarThickness: 100,
          },
        ],
      };
      setGraphData(graphData);
    }
  }, [data]);

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title="Lower Latham Reservoir Company Dashboard" />
      <Hero>
        <Welcome>
          <Typography variant="h4" style={{ color: "azure" }}>
            Welcome to the
          </Typography>
          <Typography variant="h3" style={{ color: "azure" }}>
            Lower Latham Reservoir Company Dashboard
          </Typography>

          <Typography variant="subtitle1" style={{ color: "azure" }}>
            {user?.name}
          </Typography>
        </Welcome>
      </Hero>

      <Grid mt={4} container>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                {parameters && (
                  <Typography variant="h4" ml={2}>
                    {`${
                      parameters.find(
                        (parameter) =>
                          parameter.parameter_ndx === filterValues.parameter
                      ).parameter_name
                    } - Last Report`}
                  </Typography>
                )}

                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    style={{ height: "100%", justifyContent: "center" }}
                  >
                    <RadioGroup
                      row
                      value={filterValues.parameter}
                      onChange={(e) =>
                        handleFilterChange("parameter", +e.target.value)
                      }
                    >
                      {parameters &&
                        parameters.map((parameter) => (
                          <FormControlLabel
                            key={parameter.parameter_ndx}
                            value={parameter.parameter_ndx}
                            control={<Radio />}
                            label={parameter.parameter_name}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>

                  {parameters && (
                    <FormControlLabel
                      aria-label="save graph button"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <SaveGraphButton
                          ref={saveRef}
                          title={`${
                            parameters.find(
                              (parameter) =>
                                parameter.parameter_ndx ===
                                filterValues.parameter
                            ).parameter_name
                          } - Last Report`}
                          disabled={isFetching || !data || !graphData}
                        />
                      }
                      label=""
                    />
                  )}
                </div>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TimeseriesContainer>
                <GraphWrapper>
                  {data?.length > 0 && Object.keys(graphData).length > 0 ? (
                    <DashBarChart
                      ref={saveRef}
                      data={graphData}
                      filterValues={filterValues}
                      isLoading={isFetching}
                    />
                  ) : (
                    <Loader />
                  )}
                </GraphWrapper>
              </TimeseriesContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Grid mt={4} container>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableWrapper>
                {parameters && (
                  <Table
                    isLoading={isFetching && !parameters}
                    label={`${
                      parameters.find(
                        (parameter) =>
                          parameter.parameter_ndx === filterValues.parameter
                      ).parameter_name
                    } - Last Report`}
                    columns={tableColumns}
                    data={data}
                    height="590px"
                  />
                )}
              </TableWrapper>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Recap>
        <Grid
          container
          style={{ backgroundColor: "white", alignItems: "center" }}
        >
          <Grid item xs={12} lg={4}>
            <img
              src={"/static/img/lowerlatham-logo-large-square.jpg"}
              style={{
                margin: "auto",
                display: "block",
                maxWidth: "100%",
                maxHeight: "300px",
                objectFit: "contain",
                height: "100%",
              }}
              alt="Water Waves"
            />
          </Grid>

          <Grid item xs={12} lg={8}>
            <Description color="#424242">
              <Typography
                variant="h3"
                style={{ fontWeight: "100", fontSize: "30px" }}
              >
                Enjoy the Dashboard!
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "25px",
                  lineHeight: "1.8",
                  fontSize: "1.15rem",
                }}
              >
                This tool provides access to a range of monitoring points
                relevant Lower Latham Reservoir Company. Explore monitoring
                locations for public and internal data sources using the{" "}
                <AboutLink component={NavLink} to="/data-access/map-explorer">
                  Map Explorer
                </AboutLink>{" "}
                or dig deeper into graphical visualizations on the{" "}
                <AboutLink
                  component={NavLink}
                  to="/data-access/time-series-comparisons"
                >
                  Time Series Comparisons
                </AboutLink>{" "}
                page.
              </Typography>
            </Description>
          </Grid>
        </Grid>
      </Recap>
    </React.Fragment>
  );
}

export default Default;
