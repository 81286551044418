/* eslint-disable import/first */
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Activity,
  Home,
  Monitor,
  Users,
  Map as MapIcon,
  FileText,
  HelpCircle,
  BarChart2,
  Settings,
  Smartphone,
} from "react-feather";
import Tune from "@material-ui/icons/Tune";

import async from "../components/Async";
import { ROUTES } from "../constants";
import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import DeveloperVisibilityFilter from "../components/DeveloperVisibilityFilter";
import DeveloperGuard from "../components/DeveloperGuard";

import Landing from "../pages/presentation/Landing";
import Default from "../pages/dashboards/Default";
import Mobile from "../pages/dashboards/Mobile";
import PublicMap from "../pages/publicMap";
import TimeSeriesComparison from "../pages/dataAccess/timeSeries/TimeSeriesComparison";
import LLDitchAndExt from "../pages/dataAccess/systemGraphs/LLDitchAndExt";
import LatsAndReturns from "../pages/dataAccess/systemGraphs/LatsAndReturns";
import LLResOps from "../pages/dataAccess/systemGraphs/LLResOps";
import SchmidtRch from "../pages/dataAccess/systemGraphs/SchmidtRch";
import Kersey1Rch from "../pages/dataAccess/systemGraphs/Kersey1Rch";
import Kersey2Rch from "../pages/dataAccess/systemGraphs/Kersey2Rch";
import KleinPond1Rch from "../pages/dataAccess/systemGraphs/KleinPond1Rch";
import AlertsManagement from "../pages/dataAccess/systemManagement/AlertsManagement";
import Contacts from "../pages/dataAccess/systemManagement/Contacts";
import Groups from "../pages/dataAccess/systemManagement/Groups";
import GroupsToContacts from "../pages/dataAccess/associations/GroupsToContacts";
import LocationsSetup from "../pages/dataAccess/systemManagement/LocationsSetup/LocationsSetup";
import WaterbodiesSetup from "../pages/dataAccess/systemManagement/WaterbodiesSetup/WaterbodiesSetup";
import ImportsConfiguration from "../pages/dataAccess/systemManagement/ImportsConfiguration";
import SmsHelp from "../pages/dataAccess/systemManagement/SmsHelp";
import SitesSummaryTable from "../pages/dataAccess/Reports/SitesSummaryTable";
import AdminResources from "../pages/dataAccess/AdminResources";
import LowerLathamReservoir from "../pages/dataAccess/controls/LowerLathamReservoir";
import QueryAndDownload from "../pages/dataAccess/queryAndDownload";
import { YoutubeSearchedFor } from "@material-ui/icons";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const landingRoute = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoute = {
  header: "Dashboards",
  id: "Operations Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
};

const mobileDashRoute = {
  id: "Mobile Dashboard",
  path: "/mobile-dashboard",
  icon: <Smartphone />,
  component: Mobile,
  children: null,
  guard: AuthGuard,
};

const publicMapRoute = {
  header: "Data Access",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/data-access/map-explorer",
  name: "Map Explore",
  component: PublicMap,
  guard: AuthGuard,
};

const timeSeriesComparisonsRoute = {
  id: "Time Series Comparisons",
  icon: <Activity />,
  path: "/data-access/time-series-comparisons",
  name: "Time Series Comparisons",
  component: TimeSeriesComparison,
  guard: AuthGuard,
};

const queryAndDownloadRoute = {
  id: "Query & Download",
  icon: <YoutubeSearchedFor />,
  path: "/data-access/query-and-download",
  name: "Query & Download",
  component: QueryAndDownload,
  guard: AuthGuard,
};

const systemGraphsRoutes = {
  id: "System Graphs",
  open: true,
  icon: <BarChart2 />,
  children: [
    {
      path: "/data-access/system-graphs/ll-ditch-and-ext",
      name: "LL Ditch & Ext",
      component: LLDitchAndExt,
    },
    {
      path: "/data-access/system-graphs/lats-and-returns",
      name: "Lats & Returns",
      component: LatsAndReturns,
    },
    {
      path: "/data-access/system-graphs/ll-res-ops",
      name: "LL Res Ops",
      component: LLResOps,
    },
    {
      path: "/data-access/system-graphs/schmidt-rch",
      name: "Schmidt Rch",
      component: SchmidtRch,
    },
    {
      path: "/data-access/system-graphs/kersy-1-rch",
      name: "Kersey 1 Rch",
      component: Kersey1Rch,
    },
    {
      path: "/data-access/system-graphs/kersy-2-rch",
      name: "Kersey 2 Rch",
      component: Kersey2Rch,
    },
    {
      path: "/data-access/system-graphs/klein-pond-1-rch",
      name: "Klein Pond 1 Rch",
      component: KleinPond1Rch,
    },
  ],
  guard: AuthGuard,
};

const SystemManagementRoutes = {
  id: "System Management",
  icon: <Settings />,
  children: [
    {
      path: "/data-access/system-management/alerts-management",
      name: "Alerts Management",
      component: AlertsManagement,
    },
    {
      path: "/data-access/system-management/contacts",
      name: "Contacts",
      component: Contacts,
    },
    {
      path: "/data-access/system-management/groups",
      name: "Groups",
      component: Groups,
    },
    {
      path: "/data-access/system-management/associate-contacts-and-groups",
      name: "Associate Groups with Contacts",
      component: GroupsToContacts,
    },
    {
      path: "/data-access/system-management/locations-setup",
      name: "Locations Setup",
      component: LocationsSetup,
    },
    {
      path: "/data-access/system-management/waterbodies-setup",
      name: "Waterbodies Setup",
      component: WaterbodiesSetup,
    },
    {
      path: "/data-access/system-management/imports-configuration",
      name: "Imports Configuration",
      component: ImportsConfiguration,
    },
    {
      path: "/data-access/system-management/sms-help",
      name: "SMS Help",
      component: SmsHelp,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const reportsRoutes = {
  id: "Site Summary Table",
  icon: <FileText />,
  path: "/data-access/site-summary-table",
  name: "Site Summary Table",
  component: SitesSummaryTable,
  guard: AuthGuard,
};

const resourcesRoutes = {
  id: "Admin Resources",
  icon: <HelpCircle />,
  path: "/data-access/admin-resources",
  name: "Admin Resources",
  component: AdminResources,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

const ControlsRoutes = {
  id: "Controls",
  icon: <Tune />,
  children: [
    {
      path: "/data-access/controls/lower-latham--reservoir",
      name: "Lower Latham Reservoir",
      component: LowerLathamReservoir,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  guard: AuthGuard,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

export const dashboardLayoutRoutes = [
  mainRoute,
  mobileDashRoute,
  timeSeriesComparisonsRoute,
  queryAndDownloadRoute,
  systemGraphsRoutes,
  SystemManagementRoutes,
  reportsRoutes,
  resourcesRoutes,
  ControlsRoutes,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [publicMapRoute];

export const authLayoutRoutes = [accountRoutes];

export const presentationLayoutRoutes = [landingRoute];

export const fullscreenMapRoutes = [];

export const sidebarRoutes = [
  mainRoute,
  mobileDashRoute,
  publicMapRoute,
  timeSeriesComparisonsRoute,
  queryAndDownloadRoute,
  systemGraphsRoutes,
  SystemManagementRoutes,
  reportsRoutes,
  resourcesRoutes,
  ControlsRoutes,
];
