import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import React, { useMemo } from "react";
import useFetchData from "../../../../hooks/useFetchData";
import { colorToHex } from "../utils";

export const useLocations = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedLocation, setSelectedLocation] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-list-locations"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-list-locations`,
          { headers }
        );

        return data.map((item) => ({
          ...item,
          legend_color: colorToHex(item.legend_color),
        }));
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [locationTypeLookup] = useFetchData("list-location-types", [], true);
  const locationTypeLookupForTable = useMemo(() => {
    let converted = {};
    if (locationTypeLookup?.length > 0) {
      locationTypeLookup.forEach((item) => {
        converted[item.location_type_ndx] = item.location_type_desc;
      });
    }
    return converted;
  }, [locationTypeLookup]);

  const [waterbodyLookup] = useFetchData("list-waterbodies", [], true);
  const waterbodyLookupForTable = useMemo(() => {
    let converted = {};
    if (waterbodyLookup?.length > 0) {
      waterbodyLookup.forEach((item) => {
        converted[item.waterbody_ndx] = item.waterbody_name;
      });
    }
    return converted;
  }, [waterbodyLookup]);

  const [structureTypeLookup] = useFetchData("list-structure-types", [], true);
  const structureTypeLookupForTable = useMemo(() => {
    let converted = {};
    if (structureTypeLookup?.length > 0) {
      structureTypeLookup.forEach((item) => {
        converted[item.structure_type_ndx] = item.structure_type_desc;
      });
    }
    return converted;
  }, [structureTypeLookup]);

  const LOCATIONS_TABLE_COLUMNS = useMemo(
    () => [
      {
        title: "Location ID",
        field: "location_id",
      },
      {
        title: "Display Abbreviation",
        field: "location_display_label",
      },
      {
        title: "Location Full Name",
        field: "location_name",
        cellStyle: {
          width: 300,
          minWidth: 300,
        },
      },
      {
        title: "Legend Color",
        field: "legend_color",
        render: (rowData) => (
          <div>
            <input
              type="color"
              id="legend-color"
              value={rowData.legend_color ?? ""}
              readOnly
              disabled
              style={{ width: "100%" }}
            />
            {rowData.legend_color}
          </div>
        ),
      },
      {
        title: "Has Control?",
        field: "has_control",
        type: "boolean",
      },
      {
        title: "Latitude",
        field: "latitude",
        filtering: false,
      },
      {
        title: "Longitude",
        field: "longitude",
        filtering: false,
      },
      {
        title: "Location Type",
        field: "location_type_ndx",
        lookup: locationTypeLookupForTable,
      },
      {
        title: "Waterbody",
        field: "waterbody_ndx",
        lookup: waterbodyLookupForTable,
      },
      {
        title: "Structure Type",
        field: "structure_type_ndx",
        lookup: structureTypeLookupForTable,
      },
      {
        title: "Notes",
        field: "notes",
        filtering: false,
        cellStyle: {
          width: 300,
          minWidth: 300,
        },
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      locationTypeLookupForTable,
      waterbodyLookupForTable,
      structureTypeLookupForTable,
    ]
  );

  return {
    selectedLocation,
    setSelectedLocation,
    data,
    isFetching,
    error,
    refetch,
    LOCATIONS_TABLE_COLUMNS,
  };
};
