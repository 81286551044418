import React from "react";
import {
  DataLoader,
  FooterBox,
  GridItem,
  WidgetContainer,
} from "./styledComponents";
import PercentFull from "./PercentFull";

const PercentFullSection = React.memo(({ data, isLoading, customWrap }) => {
  return (
    <DataLoader isLoading={isLoading} isData={!!data?.length}>
      {data?.map((dataItem, i) => (
        <GridItem customWrap={customWrap} key={i}>
          <WidgetContainer
            title={dataItem.location_display_label}
            subtitle={dataItem.parameter_name}
            footer={
              <FooterBox mt={2}>
                <span>Volume: {dataItem.curr_volume_af} AF</span>
                <span>Remaining: {dataItem.remaining_af} AF</span>
              </FooterBox>
            }
            footerheight="48.5px"
          >
            <PercentFull pct_full={dataItem.pct_full} />
          </WidgetContainer>
        </GridItem>
      ))}
    </DataLoader>
  );
});

export default PercentFullSection;
