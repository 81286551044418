import React from "react";

import { groupByValueArray } from "../../../utils";
import { useQuery } from "react-query";
import axios from "axios";
import styled from "styled-components/macro";
import {
  Container,
  Box,
  Grid,
  Accordion,
  AccordionDetails,
  Typography,
  Divider as MuiDivider,
} from "@material-ui/core";
import { MobileCard } from "../../../components/MobileCard";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";
import { Loader } from "react-feather";

const Divider = styled(MuiDivider)(spacing);

function Mobile() {
  const { data, error, isFetching } = useQuery(
    [``],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/display-mobiledash-data`
        );

        const groupedData = groupByValueArray(data, "waterbody_name").sort(
          (v1, v2) => {
            return v1[0].wb_display_order < v2[0].wb_display_order
              ? -1
              : v1[0].wb_display_order > v2[0].wb_display_order
              ? 1
              : 0;
          }
        );

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title="Site Summary Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Mobile Dashboard
      </Typography>
      <Divider my={6} />
      <Container>
        {isFetching || !data?.length > 0 ? (
          <Box display="flex" justifyContent="center">
            <Loader />
          </Box>
        ) : (
          data?.map((item, i) => {
            return (
              <Accordion key={i}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box style={{ width: "100%" }}>
                    <Typography variant="h3">
                      {item[0]?.waterbody_name}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} alignItems="stretch">
                    {item.map((i) => {
                      return (
                        <MobileCard key={i.location_ndx} data={i}>
                          {i.display_order}
                        </MobileCard>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Container>
    </React.Fragment>
  );
}

export default Mobile;
