import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { Divider as MuiDivider, Typography } from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import axios from "axios";
import { formatDate, getLookupForDataSortedByField } from "../../../utils";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);

const SitesSummaryTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/site-param-summary-table"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/site-param-summary-table`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const locationTypeLookup = useMemo(
    () => getLookupForDataSortedByField(data, "location_type"),
    [data]
  );

  const parameterLookup = useMemo(
    () => getLookupForDataSortedByField(data, "parameter"),
    [data]
  );

  const waterbodyNameLookup = useMemo(
    () => getLookupForDataSortedByField(data, "waterbody_name"),
    [data]
  );

  const tabColumns = useMemo(
    () => [
      {
        title: "Location Name",
        field: "location_name",
      },
      {
        title: "Location Type",
        field: "location_type",
        lookup: locationTypeLookup,
      },
      {
        title: "Parameter",
        field: "parameter",
        lookup: parameterLookup,
      },
      {
        title: "Water Body",
        field: "waterbody_name",
        lookup: waterbodyNameLookup,
      },
      {
        title: "Starting Period of Record",
        field: "start_por",
        render: (rowData) => {
          return formatDate(rowData.start_por, "MM/dd/yyyy");
        },
      },
      {
        title: "Ending Period of Record",
        field: "end_por",
        render: (rowData) => {
          return formatDate(rowData.end_por, "MM/dd/yyyy, h:mm a");
        },
      },
      {
        title: "Latitude",
        field: "latitude",
        filtering: false,
      },
      {
        title: "Longitude",
        field: "longitude",
        filtering: false,
      },
      {
        title: "External Data Link",
        field: "loc_url",
        render: (rowData) => {
          return rowData.location_urls ? (
            <a
              key={rowData.location_urls}
              target="_blank"
              href={rowData.location_urls}
              rel="noreferrer"
            >
              More Info
            </a>
          ) : (
            "N/A"
          );
        },
        filtering: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, locationTypeLookup, parameterLookup, waterbodyNameLookup]
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      <Helmet title="Site Summary Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Site Summary Table
      </Typography>

      <Divider my={6} />
      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Site Summary Table"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              pageSize={60}
              options={{ filtering: true }}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SitesSummaryTable;
