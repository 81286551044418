import React from "react";
import { ResponsiveLine } from "@nivo/line";
import {
  ChartTooltipContainer,
  SeriesTooltipContainer,
  TooltipTypography,
} from "./styledComponents";
import { colorToHex } from "../systemManagement/utils";

const getContrastYIQ = (rgbColor) => {
  const rgbValues = rgbColor.match(/\d+/g).map(Number); // Extract RGB values
  const [r, g, b] = rgbValues;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

const CustomLineTooltip = React.memo(
  ({ slice: { points } }) => {
    const {
      data: { x: date },
    } = points[0];

    // order for legend
    const sortedPoints = [...points].sort((a, b) => {
      return a.data.display_order - b.data.display_order;
    });

    return (
      <ChartTooltipContainer backgroundcolor="black" color="white">
        <TooltipTypography>{date}</TooltipTypography>
        {sortedPoints.map((point, index) => {
          const {
            serieId: series,
            data: { y: value, units, label },
            serieColor: backgroundColor,
          } = point;
          const textColor = getContrastYIQ(backgroundColor);

          return (
            <SeriesTooltipContainer
              key={index}
              backgroundcolor={backgroundColor}
              color={textColor}
            >
              <TooltipTypography>{label || series}</TooltipTypography>
              <TooltipTypography>
                {value} {units}
              </TooltipTypography>
            </SeriesTooltipContainer>
          );
        })}
      </ChartTooltipContainer>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.slice.points[0].x === nextProps.slice.points[0].x;
  }
);

const LineChart = React.memo(({ data }) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 10, right: 10, bottom: 10, left: 35 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      enableSlices="x"
      curve="monotoneX"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickValues: 4,
      }}
      enableGridX={false}
      enableGridY={false}
      colors={({ data }) => {
        return colorToHex(data[0].legend_color);
      }}
      pointSize={4}
      pointColor={{ from: "color" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      // sliceTooltip={CustomLineTooltip}
      sliceTooltip={(props) => <CustomLineTooltip {...props} />}
    />
  );
});

export default LineChart;
