import React from "react";
import { useQueryAndDownload } from "./useQueryAndDownload";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { FormProvider } from "react-hook-form";
import { DateTime } from "../../../components/inputs/Dates";
import { SimpleSelect } from "../../../components/inputs/Selects";
import { ChipSelect } from "../../../components/inputs/Chips";
import Loader from "../../../components/Loader";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);

const ActionButton = styled(Button)`
  height: 52px;
`;

const CountValue = styled.span`
  font-weight: bold;
  font-size: 2rem;
`;

const OptionsHeader = ({
  text,
  color = "secondary",
  variant = "subtitle1",
  ...rest
}) => (
  <Typography color={color} variant={variant} {...rest}>
    {text}
  </Typography>
);

const FormSection = ({ children, title }) => (
  <>
    <Grid item xs={12}>
      <OptionsHeader text={title} />
    </Grid>
    {children}
  </>
);

const QueryAndDownload = () => {
  const {
    formMethods,
    onSubmit,
    isLoading,
    error,
    inputs,
    currentCount,
    isLoadingCurrentCount,
  } = useQueryAndDownload();

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Divider my={6} />

      <Paper p={4}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormProvider {...formMethods}>
            <Grid container spacing={3}>
              <FormSection title="Time Period">
                <Grid item xs={12} sm={4}>
                  <DateTime name="startDate" label="Start Date" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateTime name="endDate" label="End Date" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SimpleSelect
                    name="timestep"
                    label="Time Step"
                    valueField="ts_endpoint"
                    displayField="ts_desc"
                    options={inputs.timesteps.data}
                    isLoading={inputs.timesteps.isLoading}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Filter Groups">
                <Grid item xs={12} lg={6}>
                  <SimpleSelect
                    name="waterbodies"
                    label="Water Sources"
                    valueField="waterbody_ndx"
                    displayField="waterbody_name"
                    options={inputs.waterbodies.data}
                    isLoading={inputs.waterbodies.isLoading}
                    multiple
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SimpleSelect
                    name="locationTypes"
                    label="Location Types"
                    valueField="location_type_ndx"
                    displayField="location_type_desc"
                    options={inputs.locationTypes.data}
                    isLoading={inputs.locationTypes.isLoading}
                    multiple
                  />
                </Grid>
              </FormSection>

              <FormSection title="Parameters">
                <Grid item xs={12}>
                  <ChipSelect
                    name="parameters"
                    label="Parameters"
                    valueField="parameter_ndx"
                    displayField="parameter_abbrev"
                    tooltipField="parameter_name"
                    isLoading={inputs.parameters.isLoading}
                    options={inputs.parameters.data}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Locations">
                <Grid item xs={12}>
                  <ChipSelect
                    name="locations"
                    label="Locations"
                    valueField="location_ndx"
                    displayField="location_id"
                    tooltipField="location_name"
                    isLoading={inputs.locations.isLoading}
                    options={inputs.locations.data}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Available Records">
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    {isLoadingCurrentCount && <Loader />}
                    <Typography>
                      {currentCount !== null ? (
                        <>
                          <CountValue>
                            {!isLoadingCurrentCount && currentCount}
                          </CountValue>{" "}
                          records available for download
                        </>
                      ) : (
                        "Please fill out the form to see available records"
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={3} mt={2} alignItems="center">
                  <Grid item xs>
                    <ActionButton
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={
                        formMethods.formState.isSubmitting ||
                        !formMethods.formState.isDirty ||
                        isLoading
                      }
                      startIcon={
                        formMethods.formState.isSubmitting || isLoading ? (
                          <Loader color="inherit" />
                        ) : null
                      }
                    >
                      {formMethods.formState.isSubmitting || isLoading
                        ? "Preparing..."
                        : "Download"}
                    </ActionButton>
                    {error && <p>Error downloading file.</p>}
                  </Grid>
                  <Grid item>
                    <ActionButton
                      onClick={() => formMethods.reset()}
                      color="secondary"
                      variant="outlined"
                    >
                      Reset
                    </ActionButton>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <p>Error downloading file.</p>
                    </Grid>
                  )}
                </Grid>
              </FormSection>
            </Grid>
          </FormProvider>
        </form>
      </Paper>
    </>
  );
};

export default QueryAndDownload;
