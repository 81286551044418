import { useCurrentConditions } from "./useCurrentConditions";
import { useTransformedCurrentConditionsData } from "./useTransformedCurrentConditionsData";
import { useCallback, useMemo, useState } from "react";
import { Button, isWidthDown, Typography, withWidth } from "@material-ui/core";
import LineChartSection from "./LineChartSection";
import PercentFullSection from "./PercentFullSection";
import {
  BorderBox,
  HeaderBox,
  ScrollableGridContainer,
} from "./styledComponents";

const CurrentConditions = ({ displayIndex, width }) => {
  const { lastFewData, pctFullData, error } =
    useCurrentConditions(displayIndex);

  const { transformedLastFewData } = useTransformedCurrentConditionsData({
    lastFewData,
  });

  const [customWrap, setCustomWrap] = useState(true);
  const toggleCustomWrap = useCallback(
    () => setCustomWrap((prevState) => !prevState),
    []
  );
  const isMobileView = useMemo(() => isWidthDown("md", width), [width]);

  if (error) {
    return <p>Error fetching recent data summary: {error.message}</p>;
  }

  return (
    <BorderBox>
      <HeaderBox>
        <Typography variant="h5"></Typography>
        {!isMobileView && (
          <Button variant="outlined" color="primary" onClick={toggleCustomWrap}>
            View All
          </Button>
        )}
      </HeaderBox>
      <ScrollableGridContainer container spacing={3} customWrap={customWrap}>
        <LineChartSection
          customWrap={customWrap}
          data={transformedLastFewData}
          isLoading={lastFewData.isFetching}
        />

        <PercentFullSection
          customWrap={customWrap}
          data={pctFullData.data}
          isLoading={pctFullData.isFetching}
        />
      </ScrollableGridContainer>
    </BorderBox>
  );
};

export default withWidth()(CurrentConditions);
