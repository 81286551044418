import React from "react";
import styled from "styled-components/macro";

import {
  Container,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { BackgroundVideo } from "../../../components/BackgroundVideo";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 7rem;
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 470px;

  color: ${(props) =>
    props.theme.palette.type === "dark"
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

const Content = styled.div`
  position: relative;
  padding: ${(props) => props.theme.spacing(6)}px 0;
  line-height: 150%;
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)}px 0;

  color: ${(props) =>
    props.theme.palette.type === "dark"
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.secondary};
`;

const LogoBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50px;
  padding: 20px;
  display: inline-block;
  margin-bottom: 40px;
`;

const LogoText = styled(Typography)`
  font-size: 3rem;
  font-weight: 300;
  color: white;
  margin: 0;
  padding: 0;

  @media (max-width: 834px) {
    font-size: 2rem;
  }
  @media (max-width: 408px) {
    font-size: 1.5rem;
  }
`;

function Introduction() {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justify="center" spacing={4}>
          <Grid item xs={10} sm={9} md={8} lg={7}>
            <BackgroundVideo mp4={"/static/video/water.mp4"} />
            <Content>
              <LogoBackground>
                <LogoText variant="h2" gutterBottom>
                  Lower Latham Reservoir Company
                </LogoText>
              </LogoBackground>
              <Grid container justify="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    Enter and analyze data. <br />
                    Access and visualize wells & properties. <br />
                    View reports & analytics. <br />
                  </Subtitle>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Introduction;
