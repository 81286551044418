import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { DatePicker, Select } from "@lrewater/lre-react";
import {
  dateFormatter,
  extractDate,
  groupByValueArray,
  hexToRgba,
  standardizeColor,
} from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Button as MuiButton,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
  lighten,
  FormControlLabel,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import { add } from "date-fns";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import SystemGraphsMap from "../../../components/map/SystemGraphsMap";
import CurrentConditions from "./CurrentConditions";
import Loader from "../../../components/Loader";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
`;

const TimeseriesContainer = styled.div`
  height: 460px;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const MapContainer = styled.div`
  height: 406px;
  width: 100%;
`;

const SystemGraphs = ({
  defaultParameter,
  pageTitle,
  endpoints,
  parametersEndpoint,
  mapEndpoint,
  rightAxis = false,
  children = null,
  displayIndex,
}) => {
  const saveRef = useRef(null);

  const [filterValues, setFilterValues] = useState({
    endpoint: endpoints[0].value,
    parameter: defaultParameter,
    startDate: extractDate(add(new Date(), { days: -3 })),
    endDate: extractDate(new Date()),
  });

  const { data: Parameters } = useQuery(
    [parametersEndpoint],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${parametersEndpoint}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      newValues[name] = value;

      return newValues;
    });
  };

  const { data, error, isFetching, refetch } = useQuery(
    [pageTitle],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${
            filterValues.parameter
          }/${filterValues.startDate}/${extractDate(
            add(new Date(filterValues.endDate), { days: 2 })
          )}`
        );

        const groupedData = groupByValueArray(data, "location_ndx").sort(
          (v1, v2) => {
            return v1[0].display_order < v2[0].display_order
              ? -1
              : v1[0].display_order > v2[0].display_order
              ? 1
              : 0;
          }
        );

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
      cacheTime: 0,
    }
  );

  const {
    data: dataRightAxis,
    error: errorRightAxis,
    isFetching: isFetchingRightAxis,
    refetch: refetchRightAxis,
  } = useQuery(
    [pageTitle, rightAxis],
    async () => {
      if (!rightAxis) return [];
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${
            filterValues.endpoint.slice(0, -1) + "2"
          }/${filterValues.startDate}/${extractDate(
            add(new Date(filterValues.endDate), { days: 2 })
          )}`
        );

        const groupedData = groupByValueArray(data, "location_ndx").sort(
          (v1, v2) => {
            return v1[0].display_order < v2[0].display_order
              ? -1
              : v1[0].display_order > v2[0].display_order
              ? 1
              : 0;
          }
        );

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
      cacheTime: 0,
    }
  );

  const tableColumns = useMemo(
    () => [
      { title: "Location", field: "location_display_label" },
      { title: "Parameter", field: "parameter_display_label" },
      { title: "Units", field: "unit_display_label" },
      {
        title: "Timestamp",
        field: "collect_timestamp",
        render: (rowData) => {
          return dateFormatter(rowData.collect_timestamp, "MM/DD/YYYY, h:mm A");
        },
        cellStyle: {
          width: 200,
          minWidth: 200,
        },
      },
      { title: "Value", field: "result_value" },
    ],
    [data] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const [graphData, setGraphData] = useState({});
  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (data) {
      const graphData = {
        datasets: [
          ...data.map((location) => {
            return {
              data: location.map((item) => {
                return {
                  x: item.collect_timestamp,
                  y: item.result_value,
                };
              }),
              yAxisID: "yL",
              units: location[0].unit_display_label,
              pointStyle: "circle",
              fill: false,
              borderWidth: 2,
              spanGaps: true,
              pointRadius: 2,
              pointHoverRadius: 4,
              label: `${location[0].location_display_label}`,
              borderColor: location[0].legend_color,
              backgroundColor: lighten(
                standardizeColor(location[0].legend_color),
                0.5
              ),
            };
          }),
          ...(rightAxis && dataRightAxis?.length
            ? dataRightAxis.map((location) => {
                return {
                  data: location.map((item) => {
                    return {
                      x: item.collect_timestamp,
                      y: item.result_value,
                    };
                  }),
                  yAxisID: "yR",
                  units: location[0].unit_display_label,
                  pointStyle: "circle",
                  fill: true,
                  borderWidth: 1,
                  spanGaps: true,
                  pointRadius: 2,
                  pointHoverRadius: 4,
                  label: `${location[0].location_display_label} (R)`,
                  borderColor: location[0].legend_color,
                  backgroundColor: hexToRgba(
                    standardizeColor(location[0].legend_color),
                    0.5
                  ),
                };
              })
            : []),
        ],
      };
      setGraphData(graphData);
    }
  }, [data, dataRightAxis, rightAxis]);

  useLayoutEffect(() => {
    return () => {
      setGraphData([]);
    };
  }, []); //eslint-disable-line

  const firstItem = data?.[0]?.[0] ?? {};
  const { parameter_display_label = "", unit_display_label = "" } = firstItem;

  const firstItemRight = dataRightAxis?.[0]?.[0] ?? {};
  const {
    parameter_display_label: parameter_display_label_right = "",
    unit_display_label: unit_display_label_right = "",
  } = firstItemRight;

  const flattenedAndFilteredData = useMemo(() => {
    if (!data || !dataRightAxis) return []; // Return empty array if data is not available
    const combinedData = [...data, ...(dataRightAxis || [])]; // Safely concatenate data and dataRightAxis

    return combinedData
      .reduce((acc, val) => acc.concat(val), []) // Flatten the array
      .filter((record) => record.result_value !== null); // Filter out records with null result_value
  }, [data, dataRightAxis]); // Dependencies

  return (
    <>
      <Helmet title={pageTitle} />
      <Typography variant="h3" gutterBottom display="inline">
        {pageTitle}
      </Typography>

      <Divider my={6} />

      {children && children}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Recent Data Summary
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CurrentConditions displayIndex={displayIndex} />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography ml={2} variant="h4">
                  {`${pageTitle} ${parameter_display_label} ${unit_display_label}`}
                </Typography>
                <FormControlLabel
                  aria-label="save graph button"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <SaveGraphButton
                      ref={saveRef}
                      title={`${pageTitle} ${parameter_display_label} ${unit_display_label}`}
                      disabled={isFetching || !data}
                    />
                  }
                  label=""
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TimeseriesContainer>
                <TableWrapper>
                  <TimeseriesLineChart
                    data={graphData}
                    error={error || errorRightAxis}
                    isLoading={isFetching || isFetchingRightAxis}
                    reverseLegend={false}
                    filterValues={filterValues}
                    yLLabel={`${parameter_display_label} (${unit_display_label})`}
                    xLabelUnit="day"
                    minR={0}
                    //flow should have a min of 0, everything else can have negative
                    minL={filterValues.parameter === 67 ? 0 : null}
                    yRLabel={
                      rightAxis
                        ? `${parameter_display_label_right} (${unit_display_label_right})`
                        : null
                    }
                    ref={saveRef}
                    title={`${pageTitle} ${parameter_display_label} ${unit_display_label}`}
                    tooltipFormat={
                      filterValues.endpoint === endpoints[0].value
                        ? "MM-DD-YYYY, h:mm A"
                        : "MM-DD-YYYY"
                    }
                  />
                </TableWrapper>
              </TimeseriesContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="time-series"
              id="time-series"
            >
              <Typography variant="h4" ml={2}>
                Options
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  {!Parameters?.length > 0 ? (
                    <Box
                      style={{
                        width: "220px",
                        height: "100%",
                        display: "inline-block",
                      }}
                      component="span"
                    >
                      <Loader />
                    </Box>
                  ) : (
                    <Select
                      name="parameter"
                      label="Parameter"
                      variant="outlined"
                      valueField="parameter_ndx"
                      displayField="parameter_name"
                      outlineColor="primary"
                      labelColor="primary"
                      margin="normal"
                      data={Parameters}
                      value={filterValues.parameter}
                      onChange={handleFilter}
                      width={220}
                    />
                  )}
                  <Select
                    name="endpoint"
                    label="Graph Options"
                    variant="outlined"
                    valueField="value"
                    displayField="label"
                    outlineColor="primary"
                    labelColor="primary"
                    margin="normal"
                    data={endpoints}
                    value={filterValues.endpoint}
                    onChange={handleFilter}
                    style={{ width: "calc(100% - 162px - 390px)" }}
                  />
                  <DatePicker
                    name="startDate"
                    label="Start Date"
                    variant="outlined"
                    outlineColor="primary"
                    labelColor="primary"
                    value={filterValues.startDate}
                    onChange={handleFilter}
                    width={150}
                  />
                  <DatePicker
                    name="endDate"
                    label="End Date"
                    variant="outlined"
                    outlineColor="primary"
                    labelColor="primary"
                    value={filterValues.endDate}
                    onChange={handleFilter}
                    width={150}
                  />
                </Grid>

                <SubmitGrid item container>
                  <Grid>
                    <Button
                      onClick={() => {
                        refetch();
                        refetchRightAxis();
                      }}
                      type="submit"
                      color="secondary"
                      variant="contained"
                      size="large"
                      disabled={
                        !filterValues.startDate || !filterValues.endDate
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Tooltip title="Quick set dates">
                      <Button
                        onClick={() => {
                          setFilterValues((prevState) => {
                            let newValues = { ...prevState };

                            newValues.startDate = extractDate(
                              add(new Date(), { months: -6 })
                            );
                            newValues.endDate = extractDate(new Date());

                            return newValues;
                          });
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        Last 6 Months
                      </Button>
                    </Tooltip>
                    <Tooltip title="Quick set dates">
                      <Button
                        ml={3}
                        onClick={() => {
                          setFilterValues((prevState) => {
                            let newValues = { ...prevState };

                            newValues.startDate = extractDate(
                              add(new Date(), { days: -45 })
                            );
                            newValues.endDate = extractDate(new Date());

                            return newValues;
                          });
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        Last 45 Days
                      </Button>
                    </Tooltip>
                    <Tooltip title="Quick set dates">
                      <Button
                        ml={3}
                        onClick={() => {
                          setFilterValues((prevState) => {
                            let newValues = { ...prevState };

                            newValues.startDate = extractDate(
                              add(new Date(), { days: -7 })
                            );
                            newValues.endDate = extractDate(new Date());

                            return newValues;
                          });
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        Last 7 Days
                      </Button>
                    </Tooltip>
                    <Tooltip title="Quick set dates">
                      <Button
                        ml={3}
                        onClick={() => {
                          setFilterValues((prevState) => {
                            let newValues = { ...prevState };

                            newValues.startDate = extractDate(
                              add(new Date(), { days: -3 })
                            );
                            newValues.endDate = extractDate(new Date());

                            return newValues;
                          });
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        Last 3 Days
                      </Button>
                    </Tooltip>
                  </Grid>
                </SubmitGrid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableWrapper>
                <Table
                  isLoading={isFetching}
                  label={`${pageTitle} ${parameter_display_label} ${unit_display_label}`}
                  columns={tableColumns}
                  data={flattenedAndFilteredData}
                  height="590px"
                />
              </TableWrapper>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <SystemGraphsMap endpoint={mapEndpoint} />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default SystemGraphs;
