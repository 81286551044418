import { useMemo } from "react";
import { format } from "date-fns";

const LAST_FEW_LINE_CONFIG = {
  xField: "collect_timestamp",
  yField: "result_value",
  idField: "location_ndx",
  unitsField: "unit_display_label",
  idLabel: "location_display_label",
};

const formatDate = (value, dateFormat = "MM/dd/yy ' @ ' hh:mm a") =>
  format(new Date(value), dateFormat);

const transformDataForLastFourLineChart = ({
  data,
  xField,
  yField,
  idField,
  unitsField,
  idLabel,
  dateFormat = "MM/dd/yy ' @ ' hh:mm a",
}) => {
  if (!data) return [];

  const seriesMap = data.reduce((acc, item) => {
    const {
      [xField]: x,
      [yField]: y,
      [idField]: id,
      [unitsField]: units,
      [idLabel]: label,
      ...rest
    } = item;

    if (!acc[id]) {
      acc[id] = { id, data: [] };
    }

    acc[id].data.push({
      x: formatDate(x, dateFormat),
      y,
      units,
      label,
      ...rest,
    });

    return acc;
  }, {});

  return Object.values(seriesMap);
};

const useTransformedData = (data, transformFunction, config) =>
  useMemo(
    () =>
      data?.data ? transformFunction({ data: data.data, ...config }) : null,
    [data?.data, transformFunction, config]
  );

export const useTransformedCurrentConditionsData = ({ lastFewData }) => {
  const transformedLastFewData = useTransformedData(
    lastFewData,
    transformDataForLastFourLineChart,
    LAST_FEW_LINE_CONFIG
  );
  return { transformedLastFewData };
};
